<template>
  <div class="date-picker">
    <div class="select-time" :style="backGroundStyle">
      <div class="range-placeholder" :style="placeholderStyle" :class="{ range:startTime && endTime }"
           @click="selectTime('startTime')">
        {{ startTime || '选择开始时间' }}
        <i v-if="startTime" class="el-icon-circle-close" @click.stop="handleClose('startTime')" />
      </div>
      <div class="range-separator">至</div>
      <div class="range-placeholder" :style="placeholderStyle" :class="{ range:startTime && endTime}"
           @click="selectTime('endTime')">
        {{ endTime || '选择截止时间' }}
        <i v-if="endTime" class="el-icon-circle-close" @click.stop="handleClose('endTime')"></i>
      </div>
    </div>

    <el-date-picker
        ref="startTime" class="visible-date date" type="date"
        :format="format" :value-format="valueFormat"
        :picker-options="startPickerOptions"
        v-model="startTime" @change="formatDiy"
    >
    </el-date-picker>
    <el-date-picker
        ref="endTime" class="visible-date date" type="date"
        :format="format" :value-format="valueFormat"
        :picker-options="endPickerOptions"
        v-model="endTime" @change="formatDiy"
    >
    </el-date-picker>
    <el-date-picker
        v-if="false"
        class="date-rang-picker" type="daterange" :unlink-panels="true" align="center"
        range-separator="至" start-placeholder="选择开始时间" end-placeholder="选择截止时间"
        v-model="modelValue" :format="format" :value-format="valueFormat" :style="{ width }"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
// TODO 后面补上范围限制功能（如范围不能超过180天等）
// TODO 可以单独选择一项
// 自定义时间范围选择组件
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: [Array, undefined],
      default: () => []
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    valueFormat: {
      type: String,
      default: undefined
    },
    timeInterval: {
      type: Number,
      default: 0
    },
    placeholderStyle: {
      type: String,
      require: false,
      default: () => ''
    },
    backGroundStyle: {
      type: String,
      require: false,
      default: () => ''
    }
  },
  watch: {
    value: {
      deep:true,
      handler: function (val) {
        if (!val) {
          this.startTime = ''
          this.endTime = ''
        } else {
          this.startTime = val[0] || ''
          this.endTime = val[1] || ''
        }
        this.modelValue = val
      }
    },
    modelValue(val, old) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },
  data() {
    const that = this
    return {
      modelValue: '',
      startTime: '',
      endTime: '',
      diyTime: '',
      timeType: '',
      startPickerOptions: {
        disabledDate(time) {
          if (that.timeInterval && that.endTime) {
            return time.getTime() < dayjs(that.endTime).subtract(180, 'day').startOf('day').valueOf()
          }
          return false
        }
      },
      endPickerOptions: {
        disabledDate(time) {
          if (that.timeInterval && that.startTime) {
            return time.getTime() > dayjs(that.startTime).endOf('day').add(180, 'day').valueOf()
          }
          return false
        }
      }
    }
  },
  created() {
    if (!this.value) {
      this.startTime = ''
      this.endTime = ''
    } else {
      this.startTime = this.value[0] || ''
      this.endTime = this.value[1] || ''
    }
    this.modelValue = this.value
  },
  methods: {
    selectTime(type) {
      this.timeType = type
      this.$refs[type].pickerVisible = true
    },
    formatDiy(value) {
      this[this.timeType] = value
      this.modelValue = [this.startTime, this.endTime]
    },
    handleClose(key) {
      this[key] = ''
      this.modelValue = [this.startTime, this.endTime]
    }
  }
}
</script>
<style lang="scss">
.date-picker{
  position: relative;
}
.el-date-editor{
  &.date-rang-picker{
    height: 36px;
    background: #F6F6F6;
    .el-input__icon{
      display: none;
      color: #333333;
    }
    .el-range-separator{
      text-align: center;
      padding: 0 10px;
      line-height: 30px;
      width: calc(1em + 20px);
    }
    .el-range-input{
      width: calc((100% - 1em - 10px) / 2);
      background: #F6F6F6;
      font-size: 15px;
      color: #333333;
    }
  }
}
.select-time {
  //width: 240px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 3px 14px 3px 14px;
  background: #F6F6F6;

  .range-placeholder {
    min-width: 100px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    margin-left: 5px;
    padding-right: 10px;
    background-color: #F6F6F6;
    color: #333333;
    text-align: right;
    font-style: normal;
    text-decoration-line: underline;
    position: relative;
    i{
      position: absolute;
      top: calc((100% - 15px) / 2);
      right: -7px;
      color: #c0c4cc;
      display: none;
    }
    &:hover{
      i{
        display: block;
      }
    }
  }

  .range {
    color: #333333;
  }

  .range-separator {
    width: 15px;
    height: 21px;
    font-size: 15px;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin: 0 10px;
  }
}
.visible-date {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: -1;
}
</style>
